@custom-media --sm (min-width: 48em); /* 768px */
@custom-media --md (min-width: 62em); /* 992px */
@custom-media --lg (min-width: 80em); /* 1280px */

.header {
  padding: 0 3.6rem;
  background: var(--grey-1 from global);
  box-shadow: var(--shadow-nav from global);
  margin-bottom: 3.2rem;
}

@media (--md) {
  .header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.logo,
.logo-mobile {
  max-width: 12.5rem;
  min-width: 5rem;
}

.logo-mobile {
  padding: 1rem 0;
}

.logo {
  display: none;
}

@media (--md) {
  .logo {
    display: flex;
    align-items: center;
    margin-right: 15rem;
  }

  .logo-mobile {
    display: none;
  }
}

.img {
  /* height: 3.8rem; */
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (--md) {
  .nav {
    flex-direction: row-reverse;
    align-items: center;
    flex: 1;
  }
}

.menu {
  display: flex;
  width: 100%;
}

@media (--md) {
  .menu {
    display: flex;
    width: unset;
  }
}

.link {
  margin: 0 2.1rem;
  padding: 3rem 0;
  display: block;
  border-bottom: 0.4rem solid transparent;

  &:hover,
  &.active {
    transition: 0.2s ease all;
    border-color: var(--brand-red from global);
  }
}

.profile {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
}

.avatar {
  display: flex;
  align-items: center;
}

.account-wrapper {
  width: 21.3rem;
  position: absolute;
  top: 7.3rem;
  right: 2rem;
  z-index: 150;
}

.header-account-wrapper {
  position: relative;
  z-index: 150;
  display: flex;
  align-items: center;
}
