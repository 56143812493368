import { type AccountInfo, PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

const CLIENT_ID: string = process.env.CLIENT_ID;

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const authenticationParams = {
  scopes: [`${CLIENT_ID}/.default`],
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const getAccessToken = async (): Promise<string | null> => {
  await msalInstance.initialize();
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    const accessToken = await msalInstance
      .acquireTokenSilent(authenticationParams)
      .then((response) => {
        return response.accessToken;
      })
      .catch((error) => {
        // Do not fallback to interaction when running outside the context of MsalProvider. Interaction should always be done inside context.
        console.log(error);
        return null;
      });
    return accessToken as string;
  }
  return null;
};

// Account selection logic is app dependent. Adjust as needed for different use cases.
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    // @ts-expect-error: see above
    const account: AccountInfo = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});
