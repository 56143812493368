.bg {
  border: var(--alku-border-default from global);
  border-radius: 0.1rem;
  background-color: var(--alku-grey-1 from global);
  padding: 2rem 2rem 1rem 2rem;
  margin: 2rem 0rem;
  border-radius: 0.1rem;
}

.wrapper {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.loader-wrapper {
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
  width: 21.5px;
  height: 21.5px;
}

.min-width-32 {
  min-width: 32px;
}

.pl-3 {
  padding-left: 3rem;
}

.pl-6 {
  padding-left: 6rem;
}

.cursor-pointer {
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.mt-2 {
  margin-top: 2rem;
}

.font-sm {
  font-size: small;
}

.pb-1 {
  padding-bottom: 1rem;
}

.disabled {
  color: var(--alku-icon-disabled from global);
  cursor: auto;
  pointer-events: none;
}

.font-size-16 {
  font-size: 16px;
}
