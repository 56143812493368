import { Button, Text } from "@alku/ui-kit";
import * as styles from "../../app.module.css";
import { useState } from "react";
import { getReportByID } from "~/src/api/reports";
import { Report } from "~/src/api/reportTypes";
import classNames from "classnames";
import { downloadCSVFile } from "~/src/utilities/downloadReport";

interface DownloadReportProps {
  recentReport?: Report;
}

export const DownloadSpreadButton = ({ recentReport }: DownloadReportProps) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      setError("");
      const response = await getReportByID(recentReport?.id);
      const data = response.data;
      const filename = recentReport?.s3Key || "";
      downloadCSVFile(data, filename);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("Failed to download spread report. Please try again or contact support.");
    }
  };

  return (
    <div className={styles["mt-2"]}>
      <Button
        disabled={loading || recentReport === undefined}
        as="button"
        style="primary-default"
        id="primary"
        className={classNames(styles["max-width"], styles["width-100"])}
        onClick={() => handleClick()}
        title={"Download Spread Report"}
      >
        {loading ? (
          <div className={styles.loader} />
        ) : (
          <div>
            Download Spread Report
            {recentReport && (
              <Text style="normal" as="p" className={classNames(styles["pt-1"])}>
                {recentReport?.s3Key}
              </Text>
            )}
          </div>
        )}
      </Button>
      {error && (
        <div className={classNames(styles["mt-1"], styles["max-width"])}>
          <Text style="span" color="alku-brand-primary">
            {error}
          </Text>
        </div>
      )}
    </div>
  );
};
