import { Heading } from "@alku/ui-kit";
import * as styles from "./apploading.module.css";
import * as sharedStyles from "../../sharedStyles.module.css";
import { Alert } from "../../components/shared/alert/Alert";

const AppLoading = ({ errorMessage }: { errorMessage?: string | boolean }): JSX.Element => {
  const imageUrl = new URL("assets/alku-logo.png", import.meta.url);

  return (
    <div className={styles.container}>
      <div className={styles["img-container"]}>
        <img className={styles.logo} src={imageUrl.toString()} alt="ALKU" />
      </div>
      <center className={styles.info}>
        {errorMessage === undefined ? (
          <Heading as="h1" style="one">
            Loading
            <span className={sharedStyles["ellipsis-anim"]}>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </span>
          </Heading>
        ) : (
          <div className={styles["error-container"]}>
            <Alert type="error" id="signup-error">
              {`Error retrieving user data from database${typeof errorMessage === "string" ? `: ${errorMessage}` : ""}`}
            </Alert>
          </div>
        )}
      </center>
    </div>
  );
};

export default AppLoading;
