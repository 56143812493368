.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 1rem;
}

.max-width {
  max-width: 400px;
}

.loader {
  border: 3px solid var(--alku-grey-1 from global);
  border-top: 3px solid var(--alku-brand-primary from global);
  border-radius: 50%;
  width: 38px;
  height: 38px;
  animation: spin 2s linear infinite;
}

.sm-loader {
  border: 2px solid var(--alku-white from global);
  border-top: 2px solid var(--alku-brand-primary from global);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.width-100 {
  width: 100%;
}

.pt-1 {
  padding-top: 0.5rem;
}
