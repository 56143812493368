.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drop-zone {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--alku-brand-primary from global);
  border-radius: 10px;
  text-align: center;
  position: relative;
}

.upload-icon {
  font-size: 5rem !important;
  color: var(--alku-brand-primary from global);
  margin-bottom: 2rem;
}

.drop-zone button {
  margin: 1rem 0;
}

.file-size-info {
  margin-top: 10px;
  font-size: 12px;
  color: #555;
}

.file-list {
  width: 100%;
  margin-top: 2rem;
}

.file-item {
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: var(--alku-grey-1 from global);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file-error {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
}

.mr-2 {
  margin-right: 2rem;
}
