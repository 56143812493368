import { type ReactNode, useEffect, useState, useCallback, createContext, useContext } from "react";
import {
  useIsAuthenticated,
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser/dist/utils/BrowserConstants";
import { getAccessToken } from "../msal/helpers";

// Screens
import UnauthenticatedScreen from "~/src/screens/unauthenticated/Unauthenticated";
import AppLoading from "~/src/screens/appLoading/AppLoading";

// Export the provider as we need to wrap the entire app with it
interface AuthProviderProps {
  children: ReactNode;
}

type UserProfile = {
  first_name: string;
  last_name: string;
};

export const AuthContext = createContext<UserProfile>({
  first_name: "",
  last_name: "",
});

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const [authToken, setAuthToken] = useState<string | null>(null);

  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated(accounts[0]);

  const validTokenGetCurrentUser = useCallback(async () => {
    if (isAuthenticated) {
      const token = await getAccessToken();
      if (typeof token === "string") void setAuthToken(token);
    }
  }, [isAuthenticated]);

  const names = accounts[0]?.name?.split(" ") || ["", ""];
  const user: UserProfile = {
    first_name: names ? names[0] : "",
    last_name: names && names.length > 1 ? names[names.length - 1] : "",
  };

  // Fetch the current user bullhornid on initial load
  useEffect(() => {
    void validTokenGetCurrentUser();
  }, [validTokenGetCurrentUser]);

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <>
      <AppAuth />
      {authToken !== null ? ( // User is authenticated
        <AuthenticatedTemplate>
          <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
        </AuthenticatedTemplate>
      ) : isAuthenticated ? (
        <AppLoading />
      ) : null}
      {!isAuthenticated && ( // User is not authenticated
        <UnauthenticatedTemplate>
          <UnauthenticatedScreen hasAccounts={accounts.length > 0} />
        </UnauthenticatedTemplate>
      )}
    </>
  );
}

const AppAuth = (): JSX.Element => {
  useMsal();
  useMsalAuthentication(InteractionType.Redirect);
  return <></>;
};

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export const useAuth = (): UserProfile => {
  return useContext(AuthContext);
};
