import { getAccessToken } from "../auth/msal/helpers";
import axios from "axios";
import { Report } from "./reportTypes";

const { ENVIRONMENT } = process.env;

const api = axios.create({
  baseURL:
    ENVIRONMENT === "prod"
      ? "https://3k5m4ovsc6.execute-api.us-east-1.amazonaws.com/prod"
      : `https://luhclwcvl9.execute-api.us-east-1.amazonaws.com/${ENVIRONMENT || "dev"}`,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 * Initiates the generation of a new report.
 * @returns A Promise that resolves once the report generation process has been successfully initiated.
 */
export const generateReport = async () => {
  try {
    const token = await getAccessToken();
    const response = await api.get<Promise<void>>(`/startReportGeneration`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    console.error("Failed to generate report");
    throw err;
  }
};

/**
 * Fetches all reports which are not archived
 * @returns A promise that resolves to an array of report objects.
 */
export const getReports = async () => {
  try {
    const token = await getAccessToken();
    const response = await api.get<Report[]>(`/getReportsMetadata`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    console.error("Failed to get reports");
    throw err;
  }
};

/**
 * Fetches the base64-encoded content of a report by its ID.
 * @param id The unique identifier of the report to fetch.
 * @returns A Promise that resolves to a string containing the base64-encoded content of the report.
 */
export const getReportByID = async (id: string | undefined) => {
  try {
    const token = await getAccessToken();
    const response = await api.get<string>(`/getReport?id=${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (err) {
    console.error("Failed to get report by ID");
    throw err;
  }
};

/**
 * Uploads an adjustment report.
 * @param report The file to upload.
 * @returns A Promise that resolves once the report has been uploaded.
 */
export const uploadAdjustmentReport = async (report: File) => {
  try {
    const token = await getAccessToken();
    let data = new FormData();
    data.append("adjustmentsFile", report);
    const response = await api.post<void>(`/uploadSpreadAdjustments`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `multipart/form-data`,
      },
    });
    return response;
  } catch (err) {
    console.error("Failed to upload adjustment report");
    throw err;
  }
};
