import { createRoot } from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./auth/msal/helpers";
import { App } from "./App";
import { AuthProvider } from "./auth/hooks/useAuth";

const container = document.getElementById("app");

if (container !== null) {
  const root = createRoot(container);

  root.render(
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </MsalProvider>,
  );
} else {
  console.error("Cannot Find Element with #app");
}
