import { useMsal } from "@azure/msal-react";
import { type IPublicClientApplication } from "@azure/msal-browser";

import { Link } from "react-router-dom";

import { Text, FlyoutIconButton, FlyoutListItem, Avatar } from "@alku/ui-kit";
import * as styles from "./header.module.css";
import { useAuth } from "~/src/auth/hooks/useAuth";

export const Header = (): JSX.Element => {
  const { instance } = useMsal();
  const imageUrl = new URL("assets/logo.png", import.meta.url);

  const handleLogout = (logoutType: string, instance: IPublicClientApplication): void => {
    if (logoutType === "popup") {
      void instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      void instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  const { first_name, last_name } = useAuth();

  const getInitials = (first_name: string, last_name: string): string => {
    return first_name.substring(0, 1).toUpperCase() + last_name.substring(0, 1).toUpperCase();
  };

  const flyoutChildren = () => {
    return (
      <Text as="div" style="detail" color="alku-black">
        <div>
          <Avatar initials={getInitials(first_name, last_name)} size="sm" />
          {first_name} {last_name}
        </div>
      </Text>
    );
  };

  return (
    <div className={styles.header}>
      <Link to="/" className={styles.logo}>
        <img className={styles.img} src={imageUrl.toString()} alt="Spread App" />
      </Link>

      <nav aria-label="Main" id="main-nav" className={styles.nav}>
        <Link to="/" className={styles["logo-mobile"]}>
          <img className={styles.img} src={imageUrl.toString()} alt="Spread App" />
        </Link>
        <div className={styles["header-account-wrapper"]}>
          <FlyoutIconButton
            id="user-profile-menu"
            label="Spread Profile Menu"
            buttonProps={{ style: "icon-only", children: flyoutChildren() }}
            iconProps={{ symbol: "ArrowDropDownOutlinedIcon", color: "alku-brand-primary" }}
            position="left"
            type="account"
            flyoutWidth="md"
            flyoutChildren={[
              <FlyoutListItem
                key="header-sign-out"
                buttonText="Sign Out"
                iconSymbol="LogoutOutlinedIcon"
                buttonProps={{ id: `profile-menu-button` }}
                onClick={() => {
                  handleLogout("redirect", instance);
                }}
              />,
            ]}
          />
        </div>
      </nav>
    </div>
  );
};
