import { Button, Modal, Text } from "@alku/ui-kit";
import * as styles from "../../app.module.css";
import { useState, useRef } from "react";
import classNames from "classnames";
import * as uploadAdjustmentStyles from "./uploadAdjustment.module.css";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { uploadAdjustmentReport } from "~/src/api/reports";

export const UploadAdjustmentButton = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [file, setFile] = useState<{ file: File | null; size: string | null }>({ file: null, size: null });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      if (selectedFile.name.endsWith(".xlsx")) {
        setFile({
          file: selectedFile,
          size: formatFileSize(selectedFile.size),
        });
        setError("");
      } else {
        setError("Please select a .xlsx file.");
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const droppedFile = event.dataTransfer.files[0];
      if (droppedFile.name.endsWith(".xlsx")) {
        setFile({
          file: droppedFile,
          size: formatFileSize(droppedFile.size),
        });
        setError("");
      } else {
        setError("Please drop a .xlsx file");
      }
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDeleteFile = () => {
    setFile({ file: null, size: null });
    setError("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleUpload = async () => {
    try {
      if (file.file) {
        setLoading(true);
        setError("");
        console.log("Uploading file:", file.file);
        await uploadAdjustmentReport(file.file);
        setLoading(false);
        setModalVisible(false);
        setSuccessModalVisible(true);
        setFile({ file: null, size: null });
      } else {
        setLoading(false);
        setError("Please select a .xlsx file.");
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response.status === 409) {
        setError(
          "There was already an adjustment uploaded for this week. Please try again later or reach out to SOS@alku.com",
        );
      } else {
        setError("There was a problem uploading adjustments - Please try again or reach out to SOS@alku.com");
      }
    }
  };

  const formatFileSize = (size: number): string => {
    if (size === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const closeModal = () => {
    setFile({ file: null, size: null });
    setError("");
    setModalVisible(false);
  };

  return (
    <>
      <div className={styles["mt-2"]}>
        <Button
          as="button"
          style="primary-default"
          id="primary"
          className={classNames(styles["max-width"], styles["width-100"])}
          onClick={() => setModalVisible(true)}
          title={"Upload Adjustment"}
        >
          <div>
            Upload Adjustment
            {
              // TODO: Hide or display this based on if adjustment have been uploaded for this week
              //   <Text style="normal" as="p" className={classNames(styles["pt-1"])}>
              //     Adjustments have been uploaded for this week
              //   </Text>
            }
          </div>
        </Button>
      </div>
      {modalVisible && (
        <Modal
          title="Upload Adjustment"
          open={modalVisible}
          onRequestClose={closeModal}
          closeOnOutsideClick
          theme="default"
        >
          <div className={uploadAdjustmentStyles["file-upload"]}>
            <div className={uploadAdjustmentStyles["drop-zone"]} onDrop={handleDrop} onDragOver={handleDragOver}>
              <FileUploadOutlinedIcon className={uploadAdjustmentStyles["upload-icon"]} />
              <Text style="title" weight="semibold">
                Drag & Drop Your File Here
              </Text>
              <div className={styles["mt-1"]}>
                <Text style="small">or</Text>
              </div>
              <Button onClick={handleButtonClick} as="button" id="primary" style="primary-default" disabled={loading}>
                Browse Computer
              </Button>
              <input
                type="file"
                accept=".xlsx"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />

              <p className={uploadAdjustmentStyles["file-size-info"]}>Supported file type: .xlsx</p>
              <p className={uploadAdjustmentStyles["file-size-info"]}>Max file size: 10MB</p>
            </div>
            {error && (
              <div className={classNames(styles["mt-1"], uploadAdjustmentStyles["file-error"])}>
                <Text style="normal" weight="bold" color="alku-brand-primary">
                  {error}
                </Text>
              </div>
            )}
            {file.file && (
              <div className={uploadAdjustmentStyles["file-list"]}>
                <div className={uploadAdjustmentStyles["file-item"]}>
                  <Text style="normal" weight="semibold" className={uploadAdjustmentStyles["file-size-info"]}>
                    {file.file.name}
                    <Text style="small" color="alku-icon-default">
                      {file.size}
                    </Text>
                  </Text>
                  <p className={uploadAdjustmentStyles["file-size-info"]}></p>
                  <Button
                    style="icon-only"
                    as="button"
                    ariaLabel="Delete file"
                    onClick={handleDeleteFile}
                    id="delete-file"
                  >
                    <DeleteOutlineOutlinedIcon fontSize="medium" />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className={uploadAdjustmentStyles["action-buttons"]}>
            <Button
              id="cancel"
              style="primary-default"
              type="submit"
              as="button"
              className={uploadAdjustmentStyles["mr-2"]}
              onClick={closeModal}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              id="upload"
              style="primary-impact"
              type="submit"
              as="button"
              disabled={!file.file}
              onClick={handleUpload}
            >
              {loading ? (
                <div className={styles["loader"]} style={{ width: "2rem", height: "2rem" }} />
              ) : (
                <div>Upload File</div>
              )}
            </Button>
          </div>
        </Modal>
      )}

      {successModalVisible && (
        <Modal
          title="Upload Adjustment"
          open={successModalVisible}
          onRequestClose={() => setSuccessModalVisible(false)}
          closeOnOutsideClick
          theme="success"
        >
          <div>Your manual adjustments were successfully uploaded!</div>
        </Modal>
      )}
    </>
  );
};
