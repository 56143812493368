import * as styles from "./icon.module.css";
import * as classes from "../classes.module.css";
import classNames from "classnames";
import { type IconProps, iconOptions, SymbolProps } from "./Icon.types";

/**
 * Icons
 * @param {ReactNode} [children] - typically an mui icon component
 * @param {IconOptions} symbol - string of available icon options
 * @param {string} size - icon size, currently supports xs, sm, md and lg (see if we can manage w/o custom rems)
 * @param {string} color - icon color from global colors defined in root css
 * @param {string} [space] - add default spacing to left or right or both ("center") sides of icon
 * @param {boolean} [rotate] - rotate 180 when true
 */

const IconSymbol: React.FC<SymbolProps> = (props): JSX.Element => {
  const Symbol = iconOptions[props.symbol];
  return <Symbol {...props} />;
};

export const Icon: React.FC<IconProps> = (props): JSX.Element => {
  const { children, symbol, color = "alku-icon-default", size, space = "none", rotate = false } = props;
  return (
    <span
      className={classNames(styles.icon, styles[size], classes[color], styles[space], rotate ? styles.rotate : "")}
      aria-hidden
    >
      {/* Can take child icon element or symbol name of predefined icon in UI kit */}
      {children ?? (
        <IconSymbol
          fontSize="inherit"
          color="inherit"
          symbol={symbol ?? "QuestionMarkOutlinedIcon"}
          style={{ height: "1.5em", width: "1.5em" }}
        />
      )}
    </span>
  );
};
