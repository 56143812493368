import * as styles from "./alert.module.css";
import { Button, Icon } from "@alku/ui-kit";
import { type ReactNode } from "react";
import classNames from "classnames";
import { getThemeColor, getThemeIcon } from "../../componentHelpers";

/**
 * Alert
 * @param {ReactNode} children - support for text and hyperlinks
 * @param {string} type - error, alert, success, and info currently supported
 */
export const Alert = ({
  children,
  type = "error",
  dismissable = false,
  id,
  size = "md",
}: {
  children: ReactNode;
  type: "info" | "alert" | "success" | "error";
  dismissable?: boolean;
  id: string;
  size?: "sm" | "md";
}): JSX.Element => {
  return (
    <div className={classNames(styles.container, styles[type], styles[size])}>
      <div className={styles.content}>
        <Icon symbol={getThemeIcon(type)} color={getThemeColor(type)} size={size === "sm" ? "md" : "xl"} />
        <div className={styles.text}>{children}</div>
      </div>
      {dismissable ? (
        <Button
          as="button"
          type="button"
          id={id}
          style="icon-only"
          ariaLabel="close"
          // onClick={} TODO: once we have an alert needed in a user story
        >
          <Icon
            symbol="CloseOutlinedIcon"
            color={
              type === "info"
                ? "bright-blue"
                : type === "alert"
                  ? "icon-warn"
                  : type === "success"
                    ? "icon-check-outlined"
                    : "brand-red"
            }
            size="lg"
          />
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};
