import { Container } from "../../components/shared/container/Container";
import { Heading } from "@alku/ui-kit";
import * as styles from "../../app.module.css";
import { GenerateSpreadButton } from "../../components/generateSpread/generateSpread";
import { DownloadSpreadButton } from "../../components/downloadSpread/downloadSpread";
import { getReports } from "../../api/reports";
import { useEffect, useState } from "react";
import { Report } from "../../api/reportTypes";
import { History } from "../../components/history/History";
import { UploadAdjustmentButton } from "~/src/components/uploadAdjustment/uploadAdjustment";

const MINUTE_MILLISECONDS = 60000;

export const Home = () => {
  const [reports, setReports] = useState<Report[]>([]);
  const [recentReport, setRecentReport] = useState<Report>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getReportsData = async () => {
      try {
        const reportsData = await getReports();
        setReports(reportsData);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    getReportsData();
    const intervalId = setInterval(getReportsData, MINUTE_MILLISECONDS); // Gets reports every minute
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (reports.length > 0) {
      const report = mostRecentReport();
      setRecentReport(report);
    }
  }, [reports]);

  const mostRecentReport = () => {
    const mostRecent = reports.reduce((prev, current) => {
      const prevDate = new Date(prev.requestedAtTime);
      const currentDate = new Date(current.requestedAtTime);
      const currentCompleted = current.completedStatus === "completed";
      const prevCompleted = prev.completedStatus === "completed";

      if (!prevCompleted || (currentCompleted && currentDate > prevDate)) {
        return current;
      }
      return prev;
    });
    return mostRecent;
  };

  return (
    <Container>
      <div className={styles.row}>
        <div className={styles.column}>
          <Heading as="div" style="one">
            Create Spread Report
          </Heading>
          <GenerateSpreadButton recentReport={recentReport} />
          <DownloadSpreadButton recentReport={recentReport} />
          <UploadAdjustmentButton />
        </div>
        <div className={styles.column}>
          <History reports={reports} isLoading={isLoading} />
        </div>
      </div>
    </Container>
  );
};
