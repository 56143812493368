type IconOptions =
  | "ReportProblemOutlinedIcon"
  | "InfoOutlinedIcon"
  | "CheckCircleOutlineOutlinedIcon"
  | "ErrorOutlineOutlinedIcon";

const themeIcons: Record<Theme, IconOptions> = {
  alert: "ReportProblemOutlinedIcon",
  error: "ErrorOutlineOutlinedIcon",
  info: "InfoOutlinedIcon",
  success: "CheckCircleOutlineOutlinedIcon",
};

const themeColors = {
  alert: "icon-warn",
  error: "brand-red",
  info: "bright-blue",
  success: "icon-check-outlined",
};

type Theme = "info" | "alert" | "success" | "error";

export const getThemeIcon = (theme: Theme) => {
  return themeIcons[theme];
};

export const getThemeColor = (theme: Theme) => {
  return themeColors[theme as keyof typeof themeColors];
};
