import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UIKit } from "./screens/UiKit";
import { Header } from "./components/header/Header";
import { Home } from "./screens/home/home";

export function App() {
  return (
    <>
      <Router>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/ui-kit" element={<UIKit />}></Route>
          </Routes>
        </main>
      </Router>
    </>
  );
}
