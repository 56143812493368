export const downloadCSVFile = (data: string, fileName: string) => {
  const blob = new Blob([data], { type: "text/csv" });

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute("download", fileName);

  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }, 0);
};
