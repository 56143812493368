import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import { ReactNode } from "react";

// TODO: use keyof instead of type?
// is there a way we can really only rely on the icon that needs to be imported
// (i.e. passing in the material JSX component to a prop instead of the string..)

export type IconOptions =
  | "QuestionMarkOutlinedIcon"
  | "AddOutlinedIcon"
  | "MoreVertOutlinedIcon"
  | "ArrowBackIosIcon"
  | "ArrowDropDownOutlinedIcon"
  | "LogoutOutlinedIcon"
  | "ExpandMoreOutlinedIcon"
  | "ChevronRightOutlinedIcon"
  | "CloseOutlinedIcon"
  | "ReportProblemOutlinedIcon"
  | "InfoOutlinedIcon"
  | "CheckCircleOutlineOutlinedIcon"
  | "ErrorOutlineOutlinedIcon"
  | "FileDownloadOutlinedIcon";

export const iconOptions = {
  QuestionMarkOutlinedIcon,
  AddOutlinedIcon,
  MoreVertOutlinedIcon,
  ArrowBackIosIcon,
  ArrowDropDownOutlinedIcon,
  LogoutOutlinedIcon,
  ExpandMoreOutlinedIcon,
  ChevronRightOutlinedIcon,
  CloseOutlinedIcon,
  ReportProblemOutlinedIcon,
  InfoOutlinedIcon,
  CheckCircleOutlineOutlinedIcon,
  ErrorOutlineOutlinedIcon,
  FileDownloadOutlinedIcon,
};

export interface SymbolProps {
  fontSize: "inherit";
  color: "inherit";
  symbol: IconOptions;
  style: {
    height: string;
    width: string;
  };
}

export interface IconProps {
  children?: ReactNode;
  symbol?: IconOptions;
  color: string;
  size: string;
  space?: "left" | "right" | "center" | "none";
  rotate?: boolean;
}
