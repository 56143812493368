import { Button, Text, Modal } from "@alku/ui-kit";
import classNames from "classnames";
import * as styles from "../../app.module.css";
import { generateReport } from "../../api/reports";
import { useState } from "react";
import { AxiosError } from "axios";
import { Report } from "~/src/api/reportTypes";

interface GenerateReportProps {
  recentReport?: Report;
}

export const GenerateSpreadButton = ({ recentReport }: GenerateReportProps) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  let lastUpdated = "";
  if (recentReport?.generatedAtTimestamp) {
    lastUpdated = new Date(recentReport.generatedAtTimestamp).toLocaleString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  const handleClick = async () => {
    try {
      setError("");
      setLoading(true);
      await generateReport();
      setModalVisible(true);
      setLoading(false);
    } catch (err: unknown) {
      setLoading(false);
      if (err instanceof AxiosError && err.response && err.response.status === 409) {
        setError("Report generation already in progress, please try again later.");
      } else {
        setError("Failed to generate spread report. Please try again or reach out to SOS.");
      }
    }
  };

  return (
    <>
      <Button
        disabled={loading}
        as="button"
        className={classNames(styles["max-width"], styles["mt-2"], styles["width-100"])}
        onClick={() => handleClick()}
        id="primary"
        style="primary-default"
        type="button"
        title={"Generate Spread Report"}
      >
        {loading ? (
          <div style={{ width: "16px", height: "16px" }}>
            <div className={styles["sm-loader"]} />
          </div>
        ) : (
          <div>
            Generate Spread Report
            {lastUpdated && (
              <Text style="normal" as="p" className={classNames(styles["pt-1"])}>
                Last Updated: {lastUpdated}
              </Text>
            )}
          </div>
        )}
      </Button>
      {error && (
        <div className={classNames(styles["max-width"], styles["mt-2"])}>
          <Text style="div" color="alku-brand-primary">
            {error}
          </Text>
        </div>
      )}
      {modalVisible && (
        <Modal
          title={"Generating Spread Report ..."}
          open={modalVisible}
          onRequestClose={() => setModalVisible(false)}
          closeOnOutsideClick
          theme="success"
        >
          <div className={styles["mt-2"]}>
            You will receive an email when the report is complete. If you don&apos;t receive an email within an hour,
            please reach out to SOS.
          </div>
        </Modal>
      )}
    </>
  );
};
