import { FC, MouseEventHandler, useCallback, useEffect, useRef } from "react";
import { ModalProps, Theme } from "./Modal.types";
import { createPortal } from "react-dom";
import * as styles from "./modal.module.css";
import { Button } from "../button/Button";
import { Icon } from "../icon/Icon";
import { Heading } from "../typography";
import { IconOptions } from "../icon/Icon.types";

const themeIcons: Record<Theme, IconOptions> = {
  alert: "ReportProblemOutlinedIcon",
  error: "ErrorOutlineOutlinedIcon",
  info: "InfoOutlinedIcon",
  success: "CheckCircleOutlineOutlinedIcon",
};

const themeColors = {
  alert: "icon-warn",
  error: "alku-brand-primary",
  info: "icon-info",
  success: "icon-success",
};

const getThemeIcon = (theme: Theme): IconOptions => {
  return themeIcons[theme];
};

const getThemeColor = (theme: Theme) => {
  return themeColors[theme as keyof typeof themeColors];
};

export const Modal: FC<ModalProps> = ({
  children,
  open,
  onRequestClose,
  closeOnOutsideClick,
  title,
  theme = "default",
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const lastActiveElement = useRef<HTMLElement | null>(null);

  // control the open state of the modal
  useEffect(() => {
    if (!dialogRef?.current) return;

    const dialogNode = dialogRef.current;
    if (open) {
      lastActiveElement.current = document.activeElement as HTMLElement;
      dialogNode.showModal();
      dialogNode.focus();
    } else if (dialogNode.open) {
      dialogNode.close();
      if (lastActiveElement.current instanceof HTMLElement) lastActiveElement.current.focus();
    }
  }, [open]);

  // add cancel handling
  const handleCancel = useCallback(
    (event: Event): void => {
      event.preventDefault();
      if (event.target === dialogRef.current) onRequestClose();
    },
    [onRequestClose],
  );

  useEffect(() => {
    if (!dialogRef?.current) return;
    const dialogNode = dialogRef.current;

    dialogNode.addEventListener("cancel", handleCancel);

    return () => {
      dialogNode.removeEventListener("cancel", handleCancel);
    };
  }, [dialogRef, handleCancel]);

  const handleOutsideClick: MouseEventHandler<HTMLDialogElement> = (event): void => {
    const dialogNode = dialogRef.current;
    if (closeOnOutsideClick && event.target === dialogNode) onRequestClose();
  };

  return createPortal(
    <dialog className={styles.modal} ref={dialogRef} onClick={handleOutsideClick}>
      <div className={`${styles["modal-close"] as string}`}>
        <Button as="button" id="close-modal" type="button" style="icon-only" ariaLabel="close" onClick={onRequestClose}>
          <Icon symbol="CloseOutlinedIcon" color="alku-brand-primary" size="lg" />
        </Button>
      </div>
      <div className={`${styles["modal-container"] as string}`}>
        {typeof title === "string" && (
          <div className={styles["modal-title"]}>
            {theme !== "default" && (
              <Icon symbol={getThemeIcon(theme)} color={getThemeColor(theme)} size="lg" space="right" />
            )}
            <Heading as="h3" style="three">
              {title}
            </Heading>
          </div>
        )}
        {open ? children : null}
      </div>
    </dialog>,
    document.body,
  );
};
