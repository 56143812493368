.container {
  max-width: 172.8rem; /* designs are 1440, but this is my 16 inch laptop screen, might want to know what size desktop screens most alku users will be on */
  margin: auto;
  width: 100%;
  padding: 0 3.6rem;
  position: absolute;
  top: 30vh;
}

.img-container {
  max-width: 30.6rem;
  margin: 0 auto;
}

.info {
  margin-top: 5rem;
}

.logo {
  max-width: 30.6rem;
}

.error-container {
  max-width: 64.6rem;
}
