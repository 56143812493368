import { Heading } from "@alku/ui-kit";
import * as sharedStyles from "../../sharedStyles.module.css";
import * as styles from "./unauthenticated.module.css";

interface UnauthenticatedScreenProps {
  hasAccounts: boolean;
}

const UnauthenticatedScreen = ({ hasAccounts }: UnauthenticatedScreenProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <center>
        <Heading as="h1" style="one">
          {hasAccounts ? (
            <span>User found, verifying auth state</span>
          ) : (
            <span>User is unauthenticated, redirecting to login page</span>
          )}
          <span className={sharedStyles["ellipsis-anim"]}>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </span>
        </Heading>
      </center>
    </div>
  );
};

export default UnauthenticatedScreen;
