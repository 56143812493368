import { Button, Icon, Heading, Text, Avatar, Modal } from "@alku/ui-kit";
import { Container } from "../components/shared/container/Container";

// Example Project-Specific Icon not supported in UI Kit
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";

import * as sharedStyles from "../sharedStyles.module.css";
import classNames from "classnames";
import { useState } from "react";

export function UIKit(): JSX.Element {
  const [openDefault, setOpenDefault] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  return (
    <Container>
      <div className={classNames(sharedStyles.flex, sharedStyles["align-top"], sharedStyles["space-between"])}>
        <div style={{ paddingRight: "10rem" }}>
          <Heading as="div" style="one">
            UI Kit
          </Heading>
          <br />
          <h2 id="typography">Typography</h2>
          <br />
          <h3 id="headings">Headings</h3>
          <br />
          <Heading as="div" style="one">
            Heading 1
          </Heading>
          <Heading as="h2" style="two">
            Heading 2
          </Heading>
          <Heading as="h3" style="three">
            Modal Heading
          </Heading>
          <p id="nav-text">Nav Text:</p>
          <br />
          <Text href="#" as="a" style="nav-text" color="alku-black" weight="semibold">
            Nav Item
          </Text>
          <br />
          <br />
          <p id="detail-text">Detail Text:</p>
          <br />
          <Text as="div" style="detail" color="alku-black">
            <Avatar initials="JD" size="sm" />
            Jane Doe
          </Text>
          <br />
          <Text as="p" style="detail" color="alku-black">
            1244 Boylston Street, Suite 1200
            <br />
            Boston, MA 02164
          </Text>
          <br />
          <Text as="p" style="detail" color="alku-black" weight="bold">
            Detail Text Bold
          </Text>
          <br />
          <br />
          <p id="link-text">Link Text:</p>
          <br />
          <Text href="#" as="a" style="link" color="alku-black" weight="semibold">
            Text Link Component
          </Text>
          <br />
          <br />
          <h2 id="buttons">Buttons</h2>
          <br />
          <p id="primary-button">Primary Button (impact):</p>
          <br />
          <Button id="primary" style="primary-impact" type="submit" as="button">
            Submit
          </Button>
          <br />
          <br />
          <Button id="secondary" style="primary-impact" type="submit" disabled as="button">
            Submit Disabled
          </Button>
          <br />
          <br />
          <p id="primary-button">Primary Button (default):</p>
          <br />
          <Button id="primary" style="primary-default" type="submit" as="button">
            Submit
          </Button>
          <br />
          <br />
          <Button id="secondary" style="primary-default" type="submit" disabled as="button">
            Submit Disabled
          </Button>
          <br />
          <br />
          <br />
          <p id="secondary-button">Secondary Button: </p>
          <br />
          <Button id="click" as="button" type="submit" style="secondary">
            <Icon symbol="AddOutlinedIcon" color="alku-brand-primary" size="md" space="right" />
            Click Here
          </Button>
          <br />
          <br />
          <Button id="browse" as="button" type="submit" style="secondary">
            Browse...
          </Button>
          <br />
          <br />
          <Button id="link" as="a" style="primary-impact" href="#">
            Link Button
          </Button>
          <br />
          <br />
          <br />
          <p id="function-button">Function Button:</p>
          <br />
          <Button id="icon-plus" as="button" type="button" style="function">
            <Icon symbol="AddOutlinedIcon" color="alku-black" size="lg" />
          </Button>
          <br />
          <br />
          <Button id="icon-menu" as="button" type="button" style="function">
            <Icon symbol="MoreVertOutlinedIcon" color="alku-black" size="lg" />
          </Button>
          <br />
          <br />
          <br />
          <p id="icon-only">Icon Only Button:</p>
          <br />
          <Button
            as="button"
            style="icon-only"
            ariaLabel="Back To Previous Page"
            onClick={() => {
              console.log("icon only button clicked");
            }}
            id="icon-only-button"
          >
            <Icon symbol="ArrowBackIosIcon" color="alku-brand-primary" size="lg" space="right" />
          </Button>
          <br />
          <h2 id="avatar">Avatar</h2>
          <br />
          <br />
          <Avatar initials="SA" size="md" />
          <br />
          <br />
          <h2 id="icons">Icons</h2>
          <br />
          <p>Icons can be managed in two ways:</p>
          <ol>
            <li>With a supported symbol string from the UI Kit</li>
            <li>By passing in a child component with an MUI Icon</li>
            <li>Note: if you do not have a supported symbol string or child component a question mark will render</li>
          </ol>
          <br />
          <br />
          <Icon symbol="QuestionMarkOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="AddOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="MoreVertOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="ArrowBackIosIcon" color="alku-black" size="md" />
          <Icon symbol="ArrowDropDownOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="LogoutOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="ExpandMoreOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="ChevronRightOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="CloseOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="ReportProblemOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="InfoOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="CheckCircleOutlineOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="ErrorOutlineOutlinedIcon" color="alku-black" size="md" />
          <Icon symbol="FileDownloadOutlinedIcon" color="alku-black" size="md" />
          <Icon color="alku-black" size="lg">
            <StarBorderOutlinedIcon fontSize="inherit" color="inherit" style={{ height: "1.5em", width: "1.5em" }} />
          </Icon>
          <br />
          <br />
          <h2 id="modals">Modals</h2>
          <br />
          <Button disabled={openDefault} style="primary-impact" as="button" onClick={() => setOpenDefault(true)}>
            Open Default Modal
          </Button>
          <br />
          <br />
          <Button disabled={openInfo} style="primary-impact" as="button" onClick={() => setOpenInfo(true)}>
            Open Info Modal
          </Button>
          <br />
          <br />
          <Button disabled={openAlert} style="primary-impact" as="button" onClick={() => setOpenAlert(true)}>
            Open Alert Modal
          </Button>
          <br />
          <br />
          <Button disabled={openSuccess} style="primary-impact" as="button" onClick={() => setOpenSuccess(true)}>
            Open Success Modal
          </Button>
          <br />
          <br />
          <Button disabled={openError} style="primary-impact" as="button" onClick={() => setOpenError(true)}>
            Open Error Modal
          </Button>
          {/** Default Modal */}
          <Modal
            theme="default"
            open={openDefault}
            onRequestClose={() => setOpenDefault(false)}
            closeOnOutsideClick={true}
            title="Default Modal"
          >
            <div>This is what a default modal looks like</div>
            <div>You can put anything you want in here</div>
          </Modal>
          {/** Info Modal */}
          <Modal
            theme="info"
            open={openInfo}
            onRequestClose={() => setOpenInfo(false)}
            closeOnOutsideClick={true}
            title="Info Modal"
          >
            <div>This is what an info modal looks like</div>
            <div>You can put anything you want in here</div>
          </Modal>
          {/** Alert Modal */}
          <Modal
            theme="alert"
            open={openAlert}
            onRequestClose={() => setOpenAlert(false)}
            closeOnOutsideClick={true}
            title="Alert Modal"
          >
            <div>This is what an alert modal looks like</div>
            <div>You can put anything you want in here</div>
          </Modal>
          {/** Success Modal */}
          <Modal
            theme="success"
            open={openSuccess}
            onRequestClose={() => setOpenSuccess(false)}
            closeOnOutsideClick={true}
            title="Success Modal"
          >
            <div>This is what a success modal looks like</div>
            <div>You can put anything you want in here</div>
          </Modal>
          {/** Error Modal */}
          <Modal
            theme="error"
            open={openError}
            onRequestClose={() => setOpenError(false)}
            closeOnOutsideClick={true}
            title="Error Modal"
          >
            <div>This is what an error modal looks like</div>
            <div>You can put anything you want in here</div>
          </Modal>
          <br />
          <br />
        </div>
      </div>
    </Container>
  );
}
