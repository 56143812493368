import { useState } from "react";
import * as styles from "./history.module.css";
import { Heading, Text, Icon } from "@alku/ui-kit";
import classNames from "classnames";
import * as appStyles from "../../app.module.css";
import { Report } from "~/src/api/reportTypes";
import { getReportByID } from "~/src/api/reports";
import { downloadCSVFile } from "~/src/utilities/downloadReport";

interface HistoryProps {
  reports: Report[];
  isLoading: boolean;
}
interface Month {
  month: string;
  files: File[];
  index?: number;
}

interface Year {
  year: number;
  months: Month[];
  index: number;
}

interface Months {
  months: Month[];
}

interface Files {
  files: Array<File>;
}

interface File {
  fileName: string;
  id: string;
}

interface SortedReports {
  year: number;
  months: [
    {
      month: string;
      files: [
        {
          fileName: string;
          id: string;
        },
      ];
    },
  ];
}

const ExpandableYear = ({ year, months, index }: Year) => {
  const [expanded, setExpanded] = useState(index === 0);

  return (
    <>
      <div onClick={() => setExpanded(!expanded)} className={classNames(styles.wrapper, styles["cursor-pointer"])}>
        <div>
          {expanded ? (
            <Icon symbol="ExpandMoreOutlinedIcon" color="alku-black" size="sm" space="right" /> // ExpandMoreOutlinedIcon
          ) : (
            <Icon symbol="ChevronRightOutlinedIcon" color="alku-black" size="sm" space="right" /> // ChevronRightOutlinedIcon
          )}
        </div>
        <Text as="div" style="title" weight="semibold">
          {year}
        </Text>
      </div>
      {expanded && <ExpandableMonths months={months} />}
    </>
  );
};

const ExpandableMonths = ({ months }: Months) => {
  return months.map((month: Month, i: number) => (
    <ExpandableMonth key={i} index={i} month={month.month} files={month.files} />
  ));
};

const ExpandableMonth = ({ month, files, index }: Month) => {
  const [expanded, setExpanded] = useState(index === 0);

  return (
    <>
      <div
        onClick={() => setExpanded(!expanded)}
        className={classNames(styles.wrapper, styles["pl-3"], styles["cursor-pointer"])}
      >
        <div>
          {expanded ? (
            <Icon symbol="ExpandMoreOutlinedIcon" color="alku-black" size="sm" space="right" /> // ExpandMoreOutlinedIcon
          ) : (
            <Icon symbol="ChevronRightOutlinedIcon" color="alku-black" size="sm" space="right" /> // ChevronRightOutlinedIcon
          )}
        </div>
        <Text style="title" as="div" weight="semibold">
          {month}
        </Text>
      </div>
      {expanded && <ExpandableFiles files={files} />}
    </>
  );
};

const ExpandableFiles = ({ files }: Files) => {
  return files.map((file: File, i: number) => <FileLink key={i} fileName={file.fileName} id={file.id} />);
};

const FileLink = ({ fileName, id }: File) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const handleClick = async () => {
    try {
      setError("");
      setLoader(true);
      const response = await getReportByID(id);
      const data = response.data;
      downloadCSVFile(data, fileName);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      setError("Failed to download spread report. Please try again or contact support.");
    }
  };

  return (
    <>
      <div className={classNames(styles.wrapper, styles["pl-6"])}>
        {loader ? (
          <div className={styles["loader-wrapper"]}>
            <div className={appStyles["sm-loader"]} />
          </div>
        ) : (
          <Icon symbol="FileDownloadOutlinedIcon" color="alku-black" size="sm" space="right" /> // FileDownloadOutlinedIcon
        )}
        <Text style="link" color="alku-black" weight="semibold">
          {!loader && (
            <div onClick={() => handleClick()} className={classNames(styles["cursor-pointer"], styles["font-size-16"])}>
              {fileName}
            </div>
          )}
          {loader && <div className={classNames(styles.disabled, styles["font-size-16"])}>{fileName}</div>}
        </Text>
      </div>
      {error && (
        <div className={classNames(styles["pl-6"], styles["font-sm"], styles["pb-1"])}>
          <Text style="span" color="alku-brand-primary">
            {error}
          </Text>
        </div>
      )}
    </>
  );
};

export const History = ({ reports, isLoading }: HistoryProps): JSX.Element => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function findInsertIndex(dayInt: number, files: File[]) {
    for (let i = 0; i < files.length; i++) {
      const fileDay = files[i].fileName.split("-") || [];
      if (parseInt(fileDay[1]) > dayInt) {
        return i;
      }
    }
  }

  const sortedData = reports
    .filter((report) => report.completedStatus === "completed")
    .reduce((sorted: SortedReports[], report: Report) => {
      const [month, day, year] = report?.s3Key.split("-") || [];
      const fullMonth = monthNames[parseInt(month) - 1];
      const existingYearIndex = sorted.findIndex((item) => item.year === Number(year));
      let dayInt = parseInt(day);

      if (existingYearIndex !== -1) {
        const existingMonthIndex = sorted[existingYearIndex].months.findIndex((item) => item.month === fullMonth);
        if (existingMonthIndex !== -1) {
          let insertIndex = findInsertIndex(dayInt, sorted[existingYearIndex].months[existingMonthIndex].files) ?? 0;
          sorted[existingYearIndex].months[existingMonthIndex].files.splice(insertIndex, 0, {
            fileName: report?.s3Key,
            id: report?.id,
          });
        } else {
          sorted[existingYearIndex].months.push({
            month: fullMonth,
            files: [
              {
                fileName: report?.s3Key,
                id: report?.id,
              },
            ],
          });
        }
      } else {
        sorted.push({
          year: Number(year),
          months: [
            {
              month: fullMonth,
              files: [
                {
                  fileName: report?.s3Key,
                  id: report?.id,
                },
              ],
            },
          ],
        });
      }

      // Sort months array in descending order within each year
      sorted.forEach((yearObj) => {
        yearObj.months.sort((a, b) => monthNames.indexOf(b.month) - monthNames.indexOf(a.month));
      });
      return sorted;
    }, []);

  return (
    <>
      <div>
        <Heading as="div" style="one">
          Historical Reports
        </Heading>

        {sortedData.length === 0 ? (
          isLoading ? (
            <div className={classNames(styles.center, styles.bg)}>
              <div className={appStyles.loader} />
            </div>
          ) : (
            <div className={classNames(styles.center, styles["mt-2"])}>No Historical Reports</div>
          )
        ) : (
          <div className={styles.bg}>
            {sortedData.map((year, i) => {
              return <ExpandableYear key={i} index={i} year={year.year} months={year.months} />;
            })}
          </div>
        )}
      </div>
    </>
  );
};
